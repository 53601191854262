/* You can add global styles to this file, and also import other style files */
.list-group-item{
    background-color: #ffffff99 !important;
}
.list-group-item > a {
    color: #125cbe !important;
}

.nt-title{
    display:block !important;
}